<template>
  <div class="i_page">
    <!-- 头部 -->
    <new-header :config="headerConfig"></new-header>
    <!-- 表單 -->
    <div class="i_content">
      <div class="i_from" v-if="fromData">
        <div v-for="(item, index) in alterationFrom" :key="index" class="i_from_item">
          <img v-if="alterationFrom.length>1" @click="cloneBtnData(index)" src="../../assets/cloneBtn2.png"/>
          <Input :config="item.usedUser" />
          <Input :config="item.allotmentPoint" />
          <uploadImg :uploadData="item.uploadData" :isAlteration="isAlteration" :number="index" @uploadImg="uploadImg">
          </uploadImg>
        </div>

        <div class="box_flex">
          <div class="nextBtn" @click="nextBtn">下一步</div>
          <div class="addBtn" @click="addBtn">继续添加 +</div>
        </div>

      </div>
      <!-- 售卖信息 -->
      <div v-else class="content_b">
        <div class="address_box_i">
          <div>合同持有人：武汉市江岸区后湖大道</div>
          <div>公司收件人：张东西</div>
          <div>联系方式：135974987451</div>
        </div>
        <div class="mail_box">
          <div class="number">
            <div class="ladel">寄送单号:</div>
            <input />
            <div class="confirm">确认</div>
          </div>
          <div class="number">
            <div class="ladel">物流公司:</div>
            <input class="input_i" />
          </div>
          <div class="number_i">
            <div class="ladel">备注信息:</div>
            <textarea class="input_i"></textarea>
          </div>
          <div class="price_info">
            <div>服务费：15.00</div>
            <div class="order_btn" @click="orderBtn">订单抵扣 ></div>
          </div>
          <!-- 选择订单抵扣 -->
          <div class="order_discoun">
            <div class="discoun">
              <div class="discoun_img"></div>
              <div class="text_info">
                <div class="title">香港直邮 壹森健康脂质体NMN</div>
                <div>订单编号：700000035654654</div>
                <div>订单时间：2022-11-11 12:36:32</div>
                <div>订单金额：100.00</div>
              </div>
            </div>
            <div class="status_text">已完成</div>
          </div>

          <div class="submit_btn_i" @click="submitBtn">提交</div>
        </div>
      </div>
    </div>

    
  </div>
</template>
<script>
import newHeader from "@/components/newHeader";
import uploadImg from "@/components/uploadImg";
import Input from "@/components/input";
import { Toast } from "vant";
export default {
  data() {
    return {
      isAlteration: true,
      headerConfig: {
        show: true,
        title: "信息填写",
        showKeufu: true,
      },
      fromData: true,
      show: false,
      alterationFrom: [
        {
          usedUser: {
            value: "",
            title: "合同持有人",
            inputStatus: 1,
            text: "请输入合同持有人",
            color: "#1A1A1A",
            ladelWidth:'170px'
          },
          allotmentPoint: {
            value: "",
            title: "工分数",
            inputStatus: 1,
            text: "请输入工分数",
            color: "#1A1A1A",
            ladelWidth:'170px'
          },
          uploadData: {
            title: "合同封面",
            getToken: "",
            imgConfig: "{ useCdnDomain: true }",
            value: []
          }
        }
      ],
      informationData: null
    };
  },
  components: {
    newHeader,
    uploadImg,
    Input
  },
  created() {
    this.informationData = JSON.parse(localStorage.getItem("audit_info"));
    this.init();
  },
  methods: {

    init() {
      if (this.informationData) {
        this.informationData.forEach((e, i) => {
          if (i > 0) {
            this.alterationFrom.push({
              usedUser: {
                value: "",
                title: "合同持有人",
                inputStatus: 1,
                text: "请输入合同持有人",
                color: "#1A1A1A",
                ladelWidth:'170px'
              },
              allotmentPoint: {
                value: "",
                title: "工分数",
                inputStatus: 1,
                text: "请输入工分数",
                color: "#1A1A1A",
                ladelWidth:'170px'
              },
              uploadData: {
                title: "合同封面",
                getToken: "",
                imgConfig: "{ useCdnDomain: true }",
                value: []
              }
            });
          }
        });
        this.informationData.forEach((item, index) => {
          this.alterationFrom[index].usedUser.value = item.name;
          this.alterationFrom[index].allotmentPoint.value = item.shares;
          this.alterationFrom[index].uploadData.value = item.img;
        });
      }
    },
    // 删除
    cloneBtnData(index){
      if(this.alterationFrom.length==0){
        return false
      }else{
        this.alterationFrom.splice(index,1);
      }
      
    },
    addBtn() {
      this.alterationFrom.push({
        usedUser: {
          value: "",
          title: "合同持有人",
          inputStatus: 1,
          text: "请输入合同持有人",
          color: "#1A1A1A",
          ladelWidth:'170px'
        },
        allotmentPoint: {
          value: "",
          title: "工分数",
          inputStatus: 1,
          text: "请输入工分数",
          color: "#1A1A1A",
          ladelWidth:'170px'
        },
        uploadData: {
          title: "合同封面",
          getToken: "",
          imgConfig: "{ useCdnDomain: true }",
          value: [],
        }
      });
    },
    nextBtn() {
      console.log(this.alterationFrom);
      let audit_info = [];
      for (var j = 0; j < this.alterationFrom.length; j++) {
        for (var i in this.alterationFrom[j]) {
          if (i == "uploadData") {
            if (this.alterationFrom[j][i].value.length == 0) {
              Toast({
                message: "请上传合同封面",
                icon: "info",
                duration: 1000
              });
              return false;
            }
          } else {
            if (!this.alterationFrom[j][i].value) {
              Toast({
                message: "请完善表单",
                icon: "info",
                duration: 1000
              });
              return false;
            }
          }
        }
      }
      this.alterationFrom.forEach(e => {
        audit_info.push({
          name: e.usedUser.value,
          shares: e.allotmentPoint.value,
          img: e.uploadData.value
        });
      });
      localStorage.setItem("audit_info", JSON.stringify(audit_info));
      localStorage.removeItem("orderData")
      this.$router.push("./addressFrom");
    },
    uploadImg(img, index) {
      console.log(img)
      // this.alterationFrom[index].uploadData.value=[]
      this.alterationFrom[index].uploadData.value = img;
    },
    // 订单选择
    orderBtn() {
      this.$router.push("./orderDiscount");
    },
    submitBtn() {
      this.show = true
    },
    cloneBtn(){
      this.show = false
    }
  }
};
</script>
<style lang="less" scoped>
.i_page{
  width: 100%;
  padding: 35px;
  box-sizing: border-box;
  background-color: #f4f4f4;
}
.i_content {
  min-height: calc(100vh - 162px);
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.05);
  width: 100%;
  padding: 30px 0 300px;
  box-sizing: border-box;
}

.cancel_box {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #A6C9FF 0%, #FFFFFF 50%);
  border-radius: 30px 30px 30px 30px;
  position: relative;
  text-align: center;
  padding: 51px 22px 20px;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
  }

  .text_box {
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: -70px;
      top: 0px;
    }

    >div {
      margin-bottom: 26px;
    }

    .message_title {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 34px;
      color: #1A1A1A;
    }

    .message_text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 26px;
      color: rgba(0, 0, 0, 0.8);
      text-align: left;
    }

    .message_text1 {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 26px;
      text-align: left;
      color: rgba(0, 0, 0, 0.8);
    }

    .sure_btn {
      width: 180px;
      height: 66px;
      background: #0091FF;
      border-radius: 50px 50px 50px 50px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 26px;
      color: #FFFFFF;
      text-align: center;
      line-height: 66px;
      margin-top: 40px;
      margin: 0 auto;
    }
  }

  >div {
    position: absolute;
    left: 17%;
    top: 51px;
  }
}

.i_from {
  width: 600px;
  background-color: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}

.addBtn {
  width: 450px;
  height: 66px;
  background: linear-gradient(180deg, #252324 0%, #3C3633 100%), #2F2B2B;
  border-radius: 12px 12px 12px 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #FFFFFF;
  text-align: center;
  line-height: 66px;
  margin: 20px auto;
}

.nextBtn {
  width: 450px;
  height: 80px;
  background: #f1f1f1;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #1a1a1a;
  margin: 0 auto;
}

.box_flex {
  position: fixed;
  bottom: 100px;
  left: 150px;
}

.i_from_item {
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  margin-top: 50px;
  position: relative;
  img{
    width: 20px;
    height: 20px;
    position: absolute;
    top: -20px;
    right: 0px;
  }
}

.van-toast {
  width: 300px;
  min-height: 120px;
  line-height: 40px;
}

.content_b {
  padding: 35px;
  box-sizing: border-box;
}

.address_box_i {
  width: 680px;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 20px 20px;
  padding: 52px 45px;
  box-sizing: border-box;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1A1A1A;
}

.address_box_i div {
  margin-bottom: 30px;
}

.mail_box {
  width: 680px;
  /* height: 1106px; */
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 20px 20px;
  margin-top: 20px;
  padding: 67px 45px;
  box-sizing: border-box;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1A1A1A;
}

.mail_box>div {
  display: flex;
  line-height: 60px;
  margin-bottom: 50px;
}

.mail_box .ladel {
  margin-right: 20px;
}

.number input {
  width: 389px;
  height: 60px;
  background: #F6F6F6;
  border-radius: 10px 10px 10px 10px;
  border: none;
  color: #000;
  padding-left: 20px;
  box-sizing: border-box;

}

.number .confirm {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #0091FF;
  margin-left: 10px
}

.number .input_i {
  width: 434px;
}

.number_i {
  display: flex;

}

.number_i .input_i {
  width: 434px;
  height: 180px;
  background: #F6F6F6;
  border-radius: 10px 10px 10px 10px;
  padding: 20px;
  box-sizing: border-box;
  border: none;
  margin: 0;
}
</style>
<style lang="less" scoped>
.price_info {
  display: flex;
  justify-content: space-between;
  margin-left: 52px;

  .order_btn {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #0091FF;
    margin-right: 25px;
  }
}

.content_b {
  .mail_box {
    .submit_btn_i {
      width: 450px;
      height: 80px;
      background: linear-gradient(180deg, #252324 0%, #3C3633 100%), #2F2B2B;
      border-radius: 12px 12px 12px 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #FFFFFF;
      text-align: center !important;
      line-height: 80px;
      margin: 50px 70px 0;
      display: inline-block;
    }
  }

  .order_discoun {
    width: 620px;
    height: 160px;
    background: #F6F6F6;
    border-radius: 10px 10px 10px 10px;
    padding: 20px 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .status_text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #0091FF;
      margin-top: 30px;
    }

    .discoun {
      display: flex;
      justify-content: space-between;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: rgba(26, 26, 26, 0.8);

      .text_info {
        margin-left: 13px;

        div {
          line-height: 30px;
        }

        .title {
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 22px;
          color: #1A1A1A;
        }
      }

      .discoun_img {
        width: 121px;
        height: 121px;
        border-radius: 6px 6px 6px 6px;
        background-color: skyblue;
      }
    }
  }

}
</style>
<style scoped>
.i_from_item>>>.ladelWidth{
  width: 170px;
}
</style>
